
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
   
import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Constants} from '../constant/constant' ;

@Injectable({
  providedIn: 'root'
})
export class ManagebookingService {

  private apiURL = Constants.CONSUMER_API_URL;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }

  getbookingdetails(params :any): Observable<any> { 

    return this.httpClient.post<any>(this.apiURL + '/BookingDetails' , JSON.stringify(params) ,this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }
 

  cancelTicket(param:any): Observable<any> { 

    return this.httpClient.post<any>(this.apiURL + '/CancelTicket' , JSON.stringify(param) ,this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  AgentcancelTicketOTP(param:any): Observable<any> { 

    return this.httpClient.post<any>(this.apiURL + '/AgentcancelTicketOTP' , JSON.stringify(param) ,this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  AgentCancelTicket(param:any): Observable<any> { 

    return this.httpClient.post<any>(this.apiURL + '/AgentcancelTicket' , JSON.stringify(param) ,this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error:HttpErrorResponse) {
    let errorMessage :any;
    if(error.error instanceof HttpErrorResponse) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error;
      
      //`Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
}